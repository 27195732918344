import React, { Component } from "react"
import Header from "../components/basics-compoment/Header"
import Footer from "../components/basics-compoment/Footer"
import "../myStyle.css"
import HelmetCompoment from "../components/basics-compoment/HelmetCompoment"
import TextStyle from "../components/style-compoment/TextStyle"

// markup
class CGV extends Component {

  render () {

    return (

      <React.Fragment>

        <HelmetCompoment></HelmetCompoment>

        <Header page="legalNotice"></Header>
        <div className="container">
          <TextStyle
            class="title big"
            french="Conditions générales de vente"
            english="Conditions générales de vente"
            spanish="Conditions générales de vente"
          ></TextStyle>
        </div>
       <TextStyle
          type="content"
          class="small content"
          content="Les conditions générales de vente."
          cssStyle={{marginBottom: "80px"}}
        ></TextStyle>
        <Footer></Footer>
        </React.Fragment>
    )

  }

}

export default CGV

